import * as React from 'react';
import { graphql } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';
import { Helmet } from 'react-helmet';
import Link from '../components/Link/Link';

import favicon from "../assets/icon/favicon.png";

const defaultDescription = 'For High Potential and Gifted Primary Students.';

const defaultTitle = 'For High Potential and Gifted Primary Students.';

function PageTemplate({ data }) {
  const models = data?.allBuilderModels;
  const page = models.page[0]?.content;

  return (
    <>
      <Helmet>
        <title>{(page && page.data.title) || defaultTitle}</title>
        <link rel="icon" type="image/x-icon" href={favicon}></link>
        <meta
          name="description"
          content={
            (page && page.data.description) || defaultDescription
          }
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=2.0, user-scalable=1" />
        <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet"/>
        <script src="https://assets.calendly.com/assets/external/widget.js"
                type="text/javascript"
                async>
        </script>
      </Helmet>
      {/** name of the model is landing page, change it if you decided to build*/}
      <BuilderComponent
        renderLink={Link}
        name="page"
        content={page}
      />
    </>
  );
}

export default PageTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    allBuilderModels {
      page(
        target: { urlPath: $path }
        limit: 1
        options: { cachebust: true }
      ) {
        content
      }
    }
  }
`;
